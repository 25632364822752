<template>
    <div>
        <el-tabs>
            <el-tab-pane label="Certificates">
                <el-button type="primary" @click="exportPDF('showTotal')" size="medium" style="margin:5px;">Save as pdf</el-button>
                

                <div id="showTotal">
                    <div v-for="(item,index) in event_awards" :key="index" class="certContainer">
                        <!-- <img v-if="item.real_rank>30" src="../../assets/cert_30.jpg" class="model"/>
                        <img v-if="item.real_rank <= 30 && item.real_rank>20" src="../../assets/cert_30.jpg" class="model"/>
                        <img v-if="item.real_rank <= 20 && item.real_rank>10" src="../../assets/cert_20.jpg" class="model"/>
                        <img v-if="item.real_rank <= 10" src="../../assets/cert_10.jpg" class="model"/>
                        <div class="event">{{item.application_event}}</div>
                        <div class="award">{{item.award_show_lable}}</div>

                        <div class="student">
                            <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
                        </div>
                        <div class="school">{{item.account.account_nameEN}}</div> -->


                        <img src="../../assets/cerEn1.jpg" class="model"/>
                        <!-- <div class="event">{{item.application_event}}</div> -->

                        <!-- <div class="event">Certificate of Excellence</div> -->


                        <!-- <div class="award">National {{`${item.real_rank}${rank(item.real_rank)}`}} Place</div> -->
                        <div class="student">
                            {{`${firstToUpper2(item.student.student_givenName_pinyin)} ${firstToUpper2(item.student.student_lastName_pinyin)}`}}
                        </div>
                        <div class="school">{{item.account.account_nameEN}}</div>

                        <!-- <div class="has">has been awarded</div> -->

                        <div class="award">{{item.group_name + ' - '+ item.award_show_lable}}</div>

                        <!-- <div class="miaoshu">in the 2022 Berkeley Future Business Leaders Challenge Competency Assessment</div> -->



                    </div>
                </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="证书2">
                <el-button type="primary" @click="exportPDF('showTotal')" size="medium">导出证书</el-button>
                <div id="showTotalScreen">
                    <div v-for="(item,index) in total_awards" :key="index" class="certContainer1">
                        <img src="../../../assets/FBLA/tv_10.jpg" class="model"/>
                        <div class="event">Banking & Financial Systems</div>
                        <div class="award">National First Prize</div>
                        <div class="student">
                            <span class="name">Jielun Zhou</span>
                            <span class="name">Jielun Zhou</span>
                            <span class="name">Jielun Zhou</span>
                        </div>
                        <div class="school">Jiangsu Nanjing NO.179 Example School Guangxi Nanning Science</div>
                    </div>
                </div>
            </el-tab-pane> -->
            <!-- <el-tab-pane label="项目奖">
                <el-button type="primary" @click="exportPDF('showEvent')" size="medium">导出证书</el-button>
                <div id="showEvent">
                    <div v-for="item in event_awards" :key="item._id.$id" class="container">
                        <img src="../../../assets/award.jpg" class="model"/>
                        <div class="student">{{upfirstName(item.student.student_givenName_pinyin)+' '+upfirstName(item.student.student_lastName_pinyin)}}</div>
                        <div class="account">{{item.account?item.account.account_nameEN:''}}</div>
                        <div>
                            <div class="event">{{item.application_event}} - {{award_type_label_arr[item.award_type]}}</div>
                            
                            <div class="award">National {{item.real_rank}}{{rank(item.real_rank)}} Place</div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="参赛证明">
                <el-button type="primary" @click="exportPDF('showComplete')" size="medium">导出证书</el-button>
                <div id="showComplete">
                    <div v-for="item in completes" :key="item._id.$id" class="container">
                        <img src="../../../assets/complete.jpg" class="model"/>
                        <div class="student">{{upfirstName(item.student.student_givenName_pinyin)+' '+upfirstName(item.student.student_lastName_pinyin)}}</div>
                        <div class="account">
                            <div>{{item.account?item.account.account_nameEN:''}}</div>
                            <div style="margin-top:30px;">has completed all competitive events in Economics, Financial Literacy and Business Case</div>
                        </div>
                    </div>
                </div>
            </el-tab-pane> -->
            <!-- <el-tab-pane label="成绩单">
                <el-button type="primary" @click="exportPDF('showTranscript')" size="medium" style="margin:5px;">导出PDF</el-button>

                <div id="showTranscript">
                    <div v-for="(item,index) in transcripts" :key="index" class="transcriptContainer" style="margin-bottom:10px;">
                        <img src="../../assets/transcript.jpg" class="model_p"/>
                        <div class="name">{{upfirstName(item.student_info.student_givenName_pinyin) + ' ' + upfirstName(item.student_info.student_lastName_pinyin) + ' ' + item.student_info.student_lastName + ' ' + item.student_info.student_givenName}}</div>
                        <div class="info">
                            <div>
                                <span class="tag">Email: </span>
                                <span class="content">{{item.student_info.student_email}}</span> | 
                                <span class="tag">Gender: </span>
                                <span class="content">{{item.student_info.student_gender == '男'? 'M' : 'F'}}</span> | 
                                <span class="tag">Expected Year of High School Graduation: </span>
                                <span class="content">{{item.student_info.student_graduation}}</span>
                            </div>
                            <div style="margin-top:6px">
                                <span class="tag">Chapter: </span>
                                <span class="content">{{item.account_info.account_nameEN}}</span>
                            </div>
                        </div>
                        <div class="score" >
                            <div  v-for="score in item.score_info" :key="score._id.$id">
                                <div v-if="score.score_type == 'team'" style="margin-bottom:20px;" >
                                    <div class="title">{{score.application_event + ' - ' + score.mode }}</div>
                                    <div style="margin-top:5px">Score:<span>{{score.total_score}}</span>/{{score.full_score}}  <span style="margin-left:10px;"></span>Percentile: Top <span>{{score.percentile}}</span></div>
                                    
                                    <div style="margin-top:5px"># of Competitors: {{score.competitor_count}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="score1" >
                            <div  v-for="score in item.score_info" :key="score._id.$id">
                                <div  v-if="score.score_type == 'personal'" style="margin-bottom:20px;">
                                    <div class="title">{{score.application_event + ' - ' + score.mode}}</div>
                                    <div style="margin-top:5px">Score:<span>{{score.total_score}}</span>/{{score.full_score}}  <span style="margin-left:10px;"></span>Percentile: Top <span >{{score.percentile}}</span></div>  
                                    <div style="margin-top:5px"># of Competitors：{{score.competitor_count}}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </el-tab-pane> -->
        </el-tabs>
    </div>
</template>

<script>
import { getStudentAwards,getStudentTranscripts} from '../../api/index.js'
import {getUserId,getProjectCode} from '../../utils/store'
export default {
  name: 'awardfbla',
  data () {
    return {
        project_code:getProjectCode(),
        total_awards: [],
        event_awards: [],
        completes: [],
        transcripts:[],
        type:'all',
        id:'',
        award_type_label_arr:{
            'personal total':'Individual Total Score Rank',
            'event rank':'Individual Event Rank',
            'event team rank':'Team Event Rank'
        },
        direction:1

    }
  },
  mounted () {

    this.type = this.$route.query.type
    this.id = this.$route.query.id

    this.fetchData()
  },
  methods: {
    fetchData () {

        console.log(1111,getProjectCode())
        
        getStudentAwards(getProjectCode(),getUserId()).then(res => {
            if (res.data.code === 0) {
            this.total_awards = res.data.data.filter(
                (item) => item.award_type === 'personal total'
            )
            this.event_awards = res.data.data.filter(
                (item) => item.award_type === 'event rank' || item.award_type === 'event team rank' || item.award_type === 'team total'
            )
            }
        })
        //   getCompletes().then(res => {
        //     if (res.data.code === 0) {
        //       this.completes = res.data.data
        //     }
        //   })
        getStudentTranscripts(getProjectCode(),getUserId()).then(res => {
            console.log(res)
            if (res.data.code === 0) {
                this.transcripts = res.data.data
            };

        })
    
      
    },
    rank (number) {
      var tail = number.toString().charAt(number.toString().length-1,1)
      if (tail === '1' && number !== 11) {
        return "st";
      } else if (tail === '2' && number !== 12) {
        return "nd";
      } else if (tail === '3' && number !== 13) {
        return "rd";
      } else {
        return "th";
      }
    },
    upfirstName (name) {
         return name.toLowerCase().replace(/\b([\w|‘]+)\b/g, function(word) {
                //return word.slice(0, 1).toUpperCase() + word.slice(1);
                return word.replace(word.charAt(0), word.charAt(0).toUpperCase());
            });
            
        if (name) {
            const transferName = name.toLowerCase()
      return transferName.charAt(0).toUpperCase() + transferName.slice(1)     
        }else{
            return name
        }
      
    },
    firstToUpper2(str){
        var newarr,newarr1; 
        newarr =str.toLowerCase().split(" ");
        for(var i=0;i<newarr.length;i++){
            newarr[i] = newarr[i][0].toUpperCase()+newarr[i].substring(1,newarr[i].length);
        }
        newarr1 = newarr.join(" ");
        return newarr1;
    },
    exportPDF (id) {


        var style = document.createElement('style');

        if (id == 'showTotal'){
            // style.innerHTML = "@page{size:landscape 1528px 1080px;}";
            style.innerHTML = "@page{size:A4 landscape;}";
        }else{
            // style.innerHTML = "@page{size:portrait 2480px 3508px;margin: auto 0mm}";
            style.innerHTML = "@page{size:A4 portrait;margin: auto 0mm}";
            document.getElementsByTagName("body")[0].style.zoom = 1
        }

        window.document.head.appendChild(style)

        const printData = document.getElementById(id).innerHTML


          this.PageSetup_Null();//把页眉页脚设置为空


        window.document.body.innerHTML = printData // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
        window.print() // 开始打印
    },
    PageSetup_Null(){
        var HKEY_Root,HKEY_Path,HKEY_Key;

        HKEY_Root="HKEY_CURRENT_USER";

        HKEY_Path="\\Software\\Microsoft\\Internet Explorer\\PageSetup\\";

        try{
            var Wsh=new ActiveXObject("WScript.Shell");

            HKEY_Key="header";

            Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"");

            HKEY_Key="footer";

            Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"");

        }catch(e){}

        }

  }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'aleo-bold';
    src: url('../../assets/font/aleo-bold.ttf');
}
@font-face {
    font-family: 'Aleo-Bold-2';
    src: url('../../assets/font/Aleo-Bold-2.otf');
}
@font-face {
    font-family: 'Aleo-Italic-4';
    src: url('../../assets/font/Aleo-Italic-4.otf');
}
@font-face {
    font-family: 'Aleo-Light-5';
    src: url('../../assets/font/Aleo-Light-5.otf');
}
@font-face {
    font-family: 'Aleo-Regular-7';
    src: url('../../assets/font/Aleo-Regular-7.otf');
}
.certContainer{
    position: relative;
    color: #2C2E2C;
    .model{
        width: 297mm;
        height: 208.7mm;
    }
    .event{
        position: absolute;
        text-align: center;
        // width: 297mm;
        font-size: 43px;
        top: 180px;
        // left: 86px;
        font-weight: 400;
    }
    .award{
        position: absolute;
        // width: 297mm;
        text-align: left;
        font-size: 38px;
        top: 360px;
        left: 270px;
        font-weight: 400;
    }
    .student{
        position: absolute;
        // width: 297mm;
        text-align: left;
        top: 200px;
        left: 270px;
        font-size: 36px;
        // font-style: italic;
        font-weight: 400;
        // .name{
        //     margin-right: 40px;
        // }
    }
    .school{
        position: absolute;
        // width: 297mm;
        text-align: left;
        top: 290px;
        left: 270px;
        font-size: 25px;
        font-weight: 300;
    }
    .has{
        position: absolute;
        // width: 297mm;
        text-align: center;
        top: 370px;
        // left: 86px;
        font-size: 20px;
        font-weight: 300;
    }
    .miaoshu{
        position: absolute;
        // width: 297mm;
        text-align: center;
        top: 500px;
        // left: 86px;
        font-size: 15px;
        font-weight: 300;
    }
}
.certContainer1{
    position: relative;
    color: white;
    .model{
        width: 365.28mm;
        height: 205.47mm;
        z-index: -10;   
    }
    .event{
        position: absolute;
        font-size: 70px;
        top: 100px;
        left: 86px;
        font-weight: 300;
        width: 600px;
    }
    .award{
        position: absolute;
        font-size: 90px;
        top: 300px;
        left: 86px;
        font-weight: 600;
    }
    .student{
        position: absolute;
        width: 600px;
        top: 460px;
        left: 86px;
        font-size: 40px;
        font-style: italic;
        font-weight: 300;
        .name{
            margin-right: 40px;
            word-wrap: none;
        }
    }
    .school{
        position: absolute;
        top: 570px;
        left: 86px;
        font-size: 30px;
        font-weight: 300;
    }
}

.transcriptContainer{
    position: relative;
    margin-bottom:-4px;
    .model_p{
        width: 210mm;
        height: 295mm;
        z-index: 0;
    }
    .name{
        position: absolute;
        top: 326px;
        left: 38px;
        font-size: 30px;
    
    }
    .info{
        position: absolute;
        top:384px;
        left: 38px;
        font-size: 14px;
        .tag{
            font-family: 'Aleo-Bold-2';
        }
        .content{
            font-family: 'Aleo-Regular-7';
        }
    }
    .score{
        position: absolute;
        top: 500px;
        width:320px;
        
        left:55px;
        font-size: 12px;
        font-family: 'Aleo-Regular-7';
        span{
            font-family: 'Aleo-Bold-2';
            font-size: 14px;
        }
        .title{
            font-family: 'aleo-bold';
        }
    }
    .score1{
        position: absolute;
        top: 500px;
        width:320px;
        left:434px;
        font-size: 12px;
        font-family: 'Aleo-Regular-7';
        span{
            font-family: 'Aleo-Bold-2';
            font-size: 14px;
        }
        .title{
            font-family: 'aleo-bold';
        }
    }
}

</style>
